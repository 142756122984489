@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
:root {
  --color-primary: #f5323f;
  --color-primary-light: #ff3c49;
  --color-primary-dark: #eb3235;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --background-light-1: #f1f1f1;
  --typography-color-dark-1: #333;
}

.heading-primary {
  font-size: clamp(3rem, 5vw + 1rem, 6rem);
  font-weight: 400;
}

.heading-tertiary {
  color: #333;
  text-transform: uppercase;
}

.btn {
  width: 100%;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hero {
  min-height: 90vh;
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  background-color: var(--background-light-1);
}
@media only screen and (min-width: 56.25em) {
  .hero {
    flex-direction: row;
  }
}
@media only screen and (min-width: 56.25em) {
  .hero-wrapper {
    flex: 1;
  }
}
.hero__img {
  position: relative;
}
@media only screen and (min-width: 56.25em) {
  .hero__img {
    order: 2;
  }
}
.hero__background-image {
  width: 100%;
  height: 55vh;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}
@media only screen and (min-width: 56.25em) {
  .hero__background-image {
    height: 100%;
  }
}
.hero__logo-box {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(241, 241, 241, 0) 0%, var(--background-light-1) 100%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
@media only screen and (min-width: 56.25em) {
  .hero__logo-box {
    background: linear-gradient(90deg, var(--background-light-1) 20.46%, rgba(242, 242, 242, 0) 100%);
  }
}
.hero__logo {
  height: 60%;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: 1s;
}
.hero__text-box {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  opacity: 0;
  top: -150px;
  animation: fadeIn 1s ease forwards;
  animation-delay: 0.5s;
}
@media only screen and (min-width: 37.5em) {
  .hero__text-box {
    top: 0;
  }
}
@media only screen and (min-width: 56.25em) {
  .hero__text-box {
    order: 1;
    text-align: left;
    padding-top: 70px;
    padding-bottom: 10px;
  }
}
.hero__span {
  color: var(--color-primary);
}

.container {
  width: 100%;
  max-width: 75rem;
  padding: 2.5rem 0;
  color: var(--typography-color-dark-1);
  text-align: center;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 56.25em) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 56.25em) {
  .container-wrapper:first-child {
    grid-column: span 2;
  }
}
.container__video {
  margin-bottom: 2.5rem;
}
.container__list {
  text-align: left;
  margin: 0;
  padding-left: 1.25rem;
  color: var(--color-primary);
}
.container__item {
  font-size: clamp(1rem, 5vw + 0.5rem, 1.5rem);
  margin-bottom: 2.5rem;
}
.container__item:not(:last-child) {
  margin-bottom: 0.625rem;
}
.container__item span {
  color: var(--typography-color-dark-1);
}
.container__buttons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
@media only screen and (min-width: 37.5em) {
  .container__buttons {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 56.25em) {
  .container__buttons {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 37.5em) {
  .container__btn:last-child {
    grid-column: span 2;
  }
}
@media only screen and (min-width: 56.25em) {
  .container__btn:last-child {
    grid-column: span 1;
  }
}/*# sourceMappingURL=main.css.map */