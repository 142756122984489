.item-banner {
  color: white;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  max-width: 100%;
  height: 3.5rem;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; /* El texto ocupará solo una línea */
  overflow: hidden; /* Para asegurar que el texto no se desborde */
}

.item-banner:not(:last-of-type) {
  border-right: none;
}

.inner-banner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 3.5rem;
  background-color: hsl(356, 91%, 58%);
  cursor: pointer;
}

.wrapper-banner {
  position: absolute;
  display: flex;
}

section {
  display: flex;
  animation: swipe var(--speed) linear infinite;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-50%);
  }
}