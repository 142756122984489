.hero
	min-height: 90vh
	margin-bottom: 1.25rem
	display: flex
	flex-direction: column
	background-color: var(--background-light-1)
	
	@include respond (tab-port)
		flex-direction: row
	
	&-wrapper
		@include respond (tab-port)
			flex: 1

	&__img
		position: relative

		@include respond (tab-port)
			order: 2
	
	&__background-image
		width: 100%
		height: 55vh
		object-fit: cover
		opacity: 0
		animation: fadeIn 1s ease forwards

		@include respond (tab-port)
			height: 100%
	
	&__logo-box
		width: 100%
		height: 100%
		background: linear-gradient(180deg, rgba(241, 241, 241, 0.00) 0%, var(--background-light-1) 100%)
		position: absolute
		display: flex
		justify-content: center
		align-items: center
		top: 0

		@include respond (tab-port)
			background: linear-gradient(90deg, var(--background-light-1) 20.46%, rgba(242, 242, 242, 0.00) 100%)
	
	&__logo
		height: 60%
		opacity: 0
		animation: fadeIn 1s ease forwards
		animation-delay: 1s
	
	&__text-box
		text-align: center
		display: flex
		justify-content: space-between
		align-items: center
		flex-direction: column
		position: relative
		opacity: 0
		top: -150px
		animation: fadeIn 1s ease forwards
		animation-delay: .5s

		@include respond (phone)
			top: 0
		
		@include respond (tab-port)
			order: 1
			text-align: left
			padding-top: 70px
			padding-bottom: 10px
	
	&__span
		color: var(--color-primary)

.container
	width: 100%
	max-width: 75rem
	padding: 2.5rem 0
	color: var(--typography-color-dark-1)
	text-align: center
	margin: auto
	display: grid
	grid-template-columns: 1fr

	@include respond (tab-port)
		grid-template-columns: 1fr 1fr
	
	&-wrapper
		@include respond (tab-port)
			&:first-child
				grid-column: span 2

	&__video
		margin-bottom: 2.5rem
	
	&__list
		text-align: left
		margin: 0
		padding-left: 1.25rem
		color: var(--color-primary)
	
	&__item
		font-size: clamp(1rem, 5vw + 0.5rem, 1.5rem)
		margin-bottom: 2.5rem
		
		&:not(:last-child)
			margin-bottom: 0.625rem
		
		span
			color: var(--typography-color-dark-1)
	
	&__buttons
		display: grid
		grid-template-columns: 1fr
		gap: 10px
		
		@include respond (phone)
			grid-template-columns: repeat(2, 1fr)
		
		@include respond (tab-port)
			grid-template-columns: 1fr
	
	&__btn
		@include respond (phone)
			&:last-child
				grid-column: span 2
		
		@include respond (tab-port)
			&:last-child
				grid-column: span 1