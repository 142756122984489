:root
	// COLORS
	--color-primary-light-2: hsl(356, 100%, 74%)
	--color-primary-light-1: hsl(356, 100%, 62%)
	--color-primary: hsl(356, 91%, 52%)
	--color-primary-dark-1: hsl(356, 91%, 44%)
	--color-primary-dark-2: hsl(356, 91%, 32%)

	--color-secondary-light-2: hsl(197, 49%, 76%)
	--color-secondary-light-1: hsl(197, 49%, 66%)
	--color-secondary: hsl(197, 49%, 48%)
	--color-secondary-dark-1: hsl(197, 49%, 32%)
	--color-secondary-dark-2: hsl(197, 49%, 24%)
	
	--color-grey-light-1: hsl(0, 0%, 97%)
	--color-grey-light-2: hsl(0, 0%, 94%)
	--color-grey-light-3: hsl(0, 0%, 90%)
	--color-grey-light-4: hsl(0, 0%, 85%)
	--color-grey-light-5: hsl(0, 0%, 80%)

	--color-grey-dark-1: hsl(0, 0%, 44%)
	--color-grey-dark-2: hsl(0, 0%, 36%)
	--color-grey-dark-3: hsl(0, 0%, 28%)
	--color-grey-dark-4: hsl(0, 0%, 20%)
	--color-grey-dark-5: hsl(0, 0%, 12%)

	--color-white-1: hsl(0, 0%, 98%)
	--color-white-2: hsl(0, 0%, 95%)

	--color-black-1: hsl(0, 0%, 10%)
	--color-black-2: hsl(0, 0%, 5%)

	--color-success: hsl(120, 99%, 37%)
	--color-info: hsl(206, 73%, 47%)
	--color-danger: hsl(0, 99%, 59%)
	--color-warning: hsl(45, 97%, 51%)

	--color-primary-light: #ff3c49
	--color-primary-dark: #eb3235

	--background-light-1: #f1f1f1

	--typography-color-dark-1: #333

	--heading-secondary: clamp(2rem, 5vw + 1rem, 3rem)
	--heading-tertiary: clamp(1.75, 5vw + 1rem, 2.5rem)

	--subheading-primary: clamp(1.5rem, 5vw + 1rem, 2rem)
	--subheading-secondary: clamp(1.25rem, 5vw + 1rem, 1.75rem)

	--text-primary: 1.125rem
	--text-secondary: 1rem
	--text-tertiary: .875rem